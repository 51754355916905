.table {
  margin-left: 15px;
  white-space: nowrap;
  th {
    padding-bottom: 20px;
    font-weight: 600;
  }
  td {
    padding-bottom: 15px;
  }
}



