.root {
  text-align: center;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.attachment {
  margin-bottom: 30px;
  > svg {
    cursor: pointer;
    transform: rotate(0);
    transition: 0.2s ease transform;
    &:hover {
      transform: rotate(-5deg);
    }
  }
}

.input {
  clip: rect(0, 0, 0, 0);
  opacity: 0;
  position: absolute;
}
