.root {
  text-align: center;
  font-size: 1.25em;
  margin: 70px 0;
}

.generate-line-animations(@n, @i: 1) when (@i =< @n) {
  .path@{i} {
    @delayRandSpan: 5;
    @delayRandMin: 0;
    @durationRandSpan: 8;
    @durationRandMin: 12;
    @magickPrime: 179424673;

    animation-duration: unit((floor(mod(@i * @magickPrime, @durationRandSpan)) + @durationRandMin), s);
    animation-delay: unit((floor(mod(@i * @magickPrime, @delayRandSpan)) + @delayRandMin), s);
  }
  .generate-line-animations(@n, (@i + 1));
}

.generate-dots-animations(@n, @i: 1) when (@i =< @n) {
  .dot@{i} {
    @durationRandSpan: 4;
    @durationRandMin: 6;
    @magickPrime: 687179;

    animation-duration: unit((floor(mod(@i * @magickPrime, @durationRandSpan)) + @durationRandMin), s);
    animation-delay: @i * 0.3s;
  }
  .generate-dots-animations(@n, (@i + 1));
}

.generate-line-animations(89);
.generate-dots-animations(9);

.svg {
  clear: both;
  display: block;
  max-width: 220px;
  margin: 0 auto 20px auto;
  color: var(--palettePrimary0);
}

@keyframes dotBlink {
  0% { r: 4.5; opacity: 1; }
  2% { r: 7; opacity: 0.7; }
  4% { r: 4.5; opacity: 1; }
  100% { r: 4.5; opacity: 1; }
}

@-webkit-keyframes dotBlink {
  0% { r: 4.5; opacity: 1; }
  2% { r: 7; opacity: 0.7; }
  4% { r: 4.5; opacity: 1; }
  100% { r: 4.5; opacity: 1; }
}

@keyframes pathShow {
  0% { opacity: 0.1; }
  4% { opacity: 0.6; }
  10% { opacity: 0.2; }
  32% { opacity: 0.7; }
  42% { opacity: 0.2; }
  52% { opacity: 0.7; }
  72% { opacity: 0.3; }
  92% { opacity: 0.6; }
  100% { opacity: 0.1; }
}

@-webkit-keyframes pathShow {
  0% { opacity: 0.1; }
  4% { opacity: 0.6; }
  10% { opacity: 0.2; }
  32% { opacity: 0.7; }
  42% { opacity: 0.2; }
  52% { opacity: 0.7; }
  72% { opacity: 0.3; }
  92% { opacity: 0.6; }
  100% { opacity: 0.1; }
}

@keyframes entrance {
  0% { opacity: 0; transform: scale(1.1); }
  100% { opacity: 1; transform: scale(1); }
}

@-webkit-keyframes entrance {
  0% { opacity: 0; transform: scale(1.1); }
  100% { opacity: 1; transform: scale(1); }
}

.content {
  opacity: 0;
  animation-name: entrance;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  transform-origin: center center;
}

.paths {
  stroke: currentColor;
}

.paths > path {
  opacity: 0.1;
  animation-name: pathShow;
  animation-iteration-count: infinite;
}
