.box {
  position: relative;
  z-index: 9;
  display: flex;
  box-shadow: 0px 3px 6px var(--Palette-shadow-opacity-0-1);
  border-radius: var(--BorderRadius-10);
  padding: 15px 20px;
  background: var(--paletteWhite0);
  align-items: center;
  font-weight: normal;
  margin: 0;
  color: var(--paletteBlue0);
  > a {
    min-width: 75px; // only relevant in mobile
  }
  @media(max-width: 600px) {
    padding: 10px 15px;
  }
}

.input {
  flex-grow: 2;
  > input {
    font-size: 20px; 
    padding: 0 10px 3px 20px;
    border: 0;
    width: 100%;
    height: 40px;
    font-size: 20px;
    &::placeholder {
      color: var(--Palette-base-opacity-0-4);
      -webkit-text-fill-color: var(--Palette-base-opacity-0-4);
    }

    @media(max-width: 360px) {
      font-size: 16px;
    }
  }
}

.buttonWrap {
  position: absolute;
  z-index: 1;
  bottom: -16px;
  right: 20px;
  transition: opacity 200ms ease, transform 200ms ease;
}

.keywords {
  padding: 20px 10px 10px 10px;
}

.icon {
  fill: var(--paletteBlue0);
  height: 25px;
}
