.root {
  display: inline-block;
  position: relative;
  max-width: 306px;
  border-radius: var(--Border-radius);
  border: 1px solid #0178b0;
  border-top: 1px solid #3496d3;
  box-shadow: inset 0 1px 0 #50a1d3;
  color: var(--paletteWhite0);
  background: linear-gradient(#0074a7, #005689);
  text-transform: uppercase;
  text-shadow: 0 -1px 0 #004263;
  padding: 10px 19px;
  cursor: pointer;
  font-size: inherit;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: bold;

  &:hover {
    color: var(--paletteWhite0);
    background: linear-gradient(#005689, #0074a7);
  }

  &.disconnect {
    background: linear-gradient(var(--paletteRed0), var(--paletteRed1));
    border: 1px solid var(--paletteRed0);
    box-shadow: inset 0 1px 0 var(--paletteRed0);
  }

  &.loading > div {
    opacity: 0;
  }

  > div > img {
    vertical-align: text-bottom;
    width: 104px;
    height: 27px;
    margin-left: 2px;
    margin-bottom: -1px;
  }
}

.loading_dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
