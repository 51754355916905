.root {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin-bottom: 30px;
  padding-right: 12px;
}

.type {
  margin-right: 10px;
}

.text {
  margin-right: 10px;
  min-width: 150px;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  width: 99%; // ie
  -webkit-flex: 2;
  flex: 2;
}

.remove {
  margin-top: 24px;
}
