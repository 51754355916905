.root {
  transition: opacity 200ms ease;
}

.activitiesUl {
  padding: 0;
  @media (min-width: 1200px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    > li {
      width: calc(50% - 10px);
      display: inline-block;
    }
  }
}

.stats {
  padding-bottom: 1em;
  font-size: large;
}

.profilesMatchInfo {
  padding-bottom: 30px;
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.results {
  display: flex;
  flex-direction: column;
  gap: var(--spacingMd);
  padding: 0;
}
