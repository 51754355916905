.body {
  padding: 0;
}

.postArea {
  display: flex;
  margin-bottom: 20px;
}

.textArea {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
  font-size: 21px;
  line-height: 32px;
  color: var(--paletteBlue0);
  letter-spacing: 0;
  font-weight: 400;
}

.counter {
  align-self: flex-end;
  color: var(--Palette-base-opacity-0-6);
  font-size: 13px;
  line-height: 18px;
}

.maxLimit {
  color: #3caffb;
}

.modal {
  max-width: 530px;
}
