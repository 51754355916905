.backdrop {
  z-index: 600;
  position: fixed;
  top: -10px;
  right: -10px;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
  transition: background ease 150ms;
  &.isVisible {
    pointer-events: auto;
    z-index: 1600;
    background: rgba(0, 0, 0, 0.5);
  }
}
