.root {
  z-index: 50;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  transform: translate(0, 0);
  opacity: 1;
  transition: ease transform 500ms, ease opacity 200ms;
  @media (min-width: 400px) {
    right: auto;
    width: 340px;
  }
  @media (min-width: 500px) {
    left: 70px;
  }
}

.empty {
  opacity: 0;
  transition: ease transform 200ms, ease opacity 350ms;
  transform: translate(0, 100%);
}

.title {
  font-size: 17px;
}

.button {
  float: right;
  position: relative;
  color: var(--Palette-neutral);
  fill: currentColor;
  font-size: 30px;
  line-height: 28px;
  text-align: center;
  margin-top: 7px;
  left: -7px;
  &:hover {
    * > svg {
      fill: var(--Palette-neutral);
      opacity: 0.5;
    }
  }

  svg {
    pointer-events: none;
  }
}

.content {
  display: flex;
  flex: auto;
  flex-direction: column;
  color: var(--paletteBlue0);
  max-height: calc(100vh - 60px);
  @media (min-height: 400px) {
    max-height: 350px;
  }
  @media (min-height: 680px) {
    max-height: 540px;

    ul {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacingMd);

    .toggle {
      padding-top: 4px;
      margin-left: 10px;
      > div {
        display: flex;
      }
    }
  }
}

.children {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: visible; // for width animation
  > p {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.card {
  border-radius: var(--BorderRadius-20);
  z-index: 20;
  position: relative;
  background: var(--paletteWhite0);
  padding: var(--spacingXl);
  overflow: hidden;
  transition: box-shadow ease 400ms, clip-path ease 500ms, -webkit-clip-path ease 500ms;
  will-change: clip-path, -webkit-clip-path;
  clip-path: circle(1000px at 0 100%);
  -webkit-clip-path: circle(1000px at 0 100%);

  box-shadow: 0px 12px 20px var(--Palette-shadow-opacity-0-3);
}

.collapsed {
  pointer-events: none; // safari seams to pass them there even when clipped

  > .card {
    transition: box-shadow ease 400ms, clip-path ease 300ms, -webkit-clip-path 300ms;
    clip-path: circle(0 at 0 100%);
    -webkit-clip-path: circle(0 at 0 100%);
  }
}

.collapsedCard {
  position: absolute;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  gap: var(--spacingMd);
  justify-content: space-between;
  padding: 0 var(--spacingXl);
  min-width: 210px;
  color: var(--paletteWhite0);
  z-index: 10;
  border-radius: var(--BorderRadius-30);
  pointer-events: all;
  height: 50px;
  transition: background ease 400ms, box-shadow ease 400ms;
  box-shadow: 0;

  .collapsed & {
    background: var(--palettePrimary0);
    box-shadow: 0px 12px 20px var(--Palette-shadow-opacity-0-3);
  }
}

.toggleWrapper {
  display: flex;
  flex-direction: row;
  color: var(--paletteBlue0);

  .label {
    display: none;
    svg {
      margin-top: 1px;
      margin-left: 4px;

      fill: var(--paletteBlue0);
    }
  }
}

.description {
  margin-left: 0;
}
