.root {
  display: flex;
  flex-direction: column;

  &.listView {
    height: 100%;
  }
}

.tip {
  opacity: 0.7;
  display: block;
  margin-bottom: 12px;
}

.buttonGroup {
  align-self: center;
}
