.list {
  margin-top: 10px;
}

.listTitle {
  display: block;
  margin-bottom: 5px;
  &:empty {
    display: none;
  }
}

.refresh {
  margin-top: 10px;
  display: inline-block;
  padding: 5px 0;
  color: var(--palettePrimary0);
  fill: currentColor;
  font-weight: 600;
  > svg {
    vertical-align: top;
    transform: rotate(0); // rotates in js
    transition: ease-in-out 200ms transform;
  }
}
