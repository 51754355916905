.root {
  padding-top: 10px;
  color: var(--paletteBlue0);
  > h1 {
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 22px 0;
    fill: currentColor;
    > span,
    > svg {
      vertical-align: middle;
    }
    > span {
      margin-left: 7px;
    }
  }
}

.isSeparated {
  margin-top: -3px; // just a nudge to offset the shadow (actually equal but visually off case)
  padding-top: 30px;
  border-top: 1px solid var(--Palette-base-opacity-0-1);
}

.list {
  padding: 0;
  margin: 0;
  font-size: 15px;
  > li {
    display: flex;
  }
}

.link {
  display: inline-block;
  flex-grow: 1;
  padding: 8px 0 9px 0;
  font-weight: 600;
  color: currentColor;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  > svg {
    transform: translate(0, -2px) rotate(-90deg);
    vertical-align: middle;
    margin-right: 10px;
    fill: currentColor;
    opacity: 0.5;
  }
}
