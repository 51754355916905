.header {
  padding: 12px 5px;
  margin: 20px 0 10px;
}

.wrap > .header {
  padding-left: 16px;
}

.connectButton {
  position: absolute;
  top: -80px;
  right: 0;
}

@media (max-width: 450px) {
  .connectButton {
    position: relative;
    top: 0;
    right: 0;

    button {
      width: 100%;
    }
  }
}

// needed because componets have their own horizontal padding
.nameField {
  @media (min-width: 768px) {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
}

.customValueField {
  margin-bottom: var(--spacingXl);
}
