// TODO: should this be part of FiltersBox/FiltersButton somehow?

.filtersWrap {
  display: flex;
  align-items: center;
  padding: 20px 0 13px 0;
  border-top: 1px solid var(--Palette-base-opacity-0-2);
  border-bottom: 1px solid var(--Palette-base-opacity-0-2);
  margin-bottom: 20px;
}

.filtersPreviewWrap {
  flex-grow: 1;
  overflow: hidden;
  max-width: calc(100% - 100px);
}

.sortByWrap {
  display: flex;
  gap: var(--spacingMd);
}
