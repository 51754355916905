.tippy-box[data-theme~="pf"] {
  background-color: var(--paletteBlue1);
  color: white;
  padding: 10px;
}

.tippy-box[data-theme~="pf"] > .tippy-arrow {
  color: var(--paletteBlue1);
}

.tippy-box[data-theme~="pf"] > .tippy-arrow::before {
  border-top-color: var(--paletteBlue1);
}

.tippy-box[data-theme~="pf-info"] {
  background-color: var(--Palette-base-light);
  color: white;
  padding: 10px;
  border-radius: var(--BorderRadius-10);
}

.tippy-box[data-theme~="pf-info"] > .tippy-arrow {
  color: var(--Palette-base-light);
}

.tippy-box[data-theme~="pf-info"] > .tippy-arrow::before {
  border-top-color: var(--Palette-base-light);
}

.tippy-box[data-theme~="pf-gray"] {
  background-color: var(--Color-gray-1);
  color: white;
  padding: 10px;
}

.tippy-box[data-theme~="pf-gray"] > .tippy-arrow {
  color: var(--Color-gray-1);
}

.tippy-box[data-theme~="pf-gray"] > .tippy-arrow::before {
  border-top-color: var(--Color-gray-1);
}

.tippy-box[data-theme~="pf-light"] {
  background-color: var(--Palette-neutral-mid);
  color: var(--paletteBlue0);
  padding: 20px;
}

.tippy-box[data-theme~="pf-light"] > .tippy-arrow {
  color: var(--Palette-neutral-mid);
}

.tippy-box[data-theme~="pf-light"] > .tippy-arrow::before {
  border-top-color: var(--Palette-neutral-mid);
}

.tippy-box[data-theme~="pf-white"] {
  background-color: #fff;
  color: var(--Palette-nav);
  padding: 10px;
  box-shadow: 5px 5px 6px var(--Palette-shadow-opacity-0-1);
}

.tippy-box[data-theme~="pf-white"]>.tippy-arrow {
  color: var(--Palette-nav);
}

.tippy-box[data-theme~="pf-white"][data-placement="top"]>.tippy-arrow::before {
  border-top-color: #fff;
  box-shadow: 5px 5px 5px 0px var(--Palette-shadow-opacity-0-1);
}
.tippy-box[data-theme~="pf-white"][data-placement="bottom"]>.tippy-arrow::before {
  border-bottom-color: #fff;
}
.tippy-box[data-theme~="pf-skill"] {
  background-color: #5E7A83;
  color: #fff;
  padding: 0px;
  max-width: unset !important;
  border-radius: var(--radiusMd);
  transform: translateY(10px);
}

.tippy-box[data-theme~="pf-skill"]>.tippy-arrow {
  color: #5E7A83;
}

.tippy-box[data-theme~="pf-skill"]>.tippy-arrow::before {
  display: none;
}

.tippy-box[data-theme~="pf-skill"]>.tippy-content {
  padding: 0px;
}
.tippy-box[data-theme~="pf-dark-blue"] {
  background-color: var(--Palette-nav);
  color: #fff;
}

.tippy-box[data-theme~="pf-dark-blue"]>.tippy-arrow {
  color: var(--Palette-nav);
}

.tippy-box[data-theme~="pf-dark-blue"]>.tippy-arrow::before {
  color: var(--Palette-nav);
}

.tippy-box[data-theme~="pf-dropdown"] {
  background-color: #fff;
  color: var(--Palette-nav);
  padding: 5px;
  box-shadow: 0 10px 12px rgb(0 0 0 / 10%);
  transition-duration: 0s;
}

.tippy-box[data-theme~="pf-dropdown"]>.tippy-arrow {
  display: none;
}

.tippy-box[data-theme~="pf-dropdown"]>.tippy-arrow::before {
  border-top-color: #fff;
  box-shadow: 5px 5px 5px 0px rgba(66, 68, 90, 1);
}

.tippy-box[data-theme~="pf-translucent"] {
  background-color: transparent;
  padding: 0;
  border: none;
}

.tippy-box[data-theme~="pf-translucent"] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~="pf-translucent"]>.tippy-arrow{
  color: var(--paletteBlue0);
}
