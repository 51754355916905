.title {
  display: block;
  font-size: 1.5em;
  font-weight: normal;
  color: #777;
  text-align: center;
}

.input {
  padding: 10px 10px 10px 20px;
  border: 0;
  font-size: 18px;
  display: block;
  width: 100%;
}

.inputWrap {
  margin: 20px 0 0 0;
  display: flex;
  border: 1px solid #ccc;
  > svg {
    margin: 13px 15px 0 0;
    fill: #bbb;
  }
}

.list {
  max-height: 200px;
  border: 1px solid #eee;
  border-top: 0;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  > li {
    display: flex;
    min-height: 40px;
    font-size: 16px;
    padding: 5px 10px;
    align-items: center;
    justify-items: center;
    > span {
      flex-grow: 2;
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }
}

.noResults {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  opacity: 0.5;
}

.loading {
  padding: var(--spacingMd) var(--spacingXl);
}

.close {
  position: absolute;
  top: var(--spacingXl);
  right: var(--spacingXl);
}

.modal {
  max-width: 600px;
}
