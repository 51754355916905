.root {
  padding: 0 10px 10px 10px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 700px) {
    padding: 0 10px 60px 10px;
  }
}

.export {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.header {
  margin-top: 10px;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > h1 {
    margin: 0;
    padding: 10px 0;
    font-size: 17px;
    font-weight: 600;
  }

  @media (max-width: 400px) {
    display: block;
    text-align: right;
    > h1 {
      display: none;
    }
  }
}

.resultsFound {
  display: flex;
  align-items: center;
  line-height: 100%;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
