.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.title {
  font-size: 15px;
  line-height: 24px;
  font-weight: 800;
  color: #333;
  margin-bottom: 20px;
}

.image{
  width: calc(100% + 40px);
  border-radius: 4px;
  margin-left: -20px;
  margin-bottom: 20px;
}

.imageMobile {
  max-height: 50px;
  object-fit: cover;
}

.url {
  font-size: 13px;
  line-height: 20px;
  color: #333;
  margin-bottom: 20px;
}

.removePost {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  height: 20px;
  width: 100%;
  
  svg {
    height: 20px;
    width: 20px;
    fill: var(--Color-primary);
    cursor: pointer;
  }
}
