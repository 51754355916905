.root {
  padding: 0;
  margin: 20px 0 10px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.clearButton {
  margin-left: auto;
}

.item {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  line-height: 1em;
  border-radius: var(--BorderRadius-20);
  min-height: 40px;
  font-size: 13px;
  margin: 0 10px 10px 0;
  fill: currentColor;
  border: 1px solid transparent;
  color: currentColor;
  border: 1px solid var(--Palette-neutral);
  color: var(--paletteBlue0);

  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.wrap {
  display: flex;
  align-items: stretch;
}

.text {
  display: flex;
  padding: 11px 20px 10px;
  font-weight: 600;
  line-height: 20px;
}

.addable {
  background: var(--paletteWhite0);
  color: var(--paletteBlue0);
  border: 1px solid var(--Palette-neutral);
  cursor: pointer;
}

.plus {
  display: inline-block;
  font-size: 20px;
  line-height: 14px;
  padding: 0 6px 0 2px;
}

.remove {
  position: relative;
  display: inline-block;
  z-index: 1; // over the text
  font-size: 20px;
  align-self: stretch;
  line-height: 22px;
  padding: 7px 5px 7px 13px;
  margin-right: -16px;
  color: currentColor;
}
