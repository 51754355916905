.root {
  padding: 0 10px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

// the headers in the app are rather inconsistent, so it's difficult
// to actually have <PageHeader> component for now
.header {
  text-align: center;
  margin: 60px 0 39px 0;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;

  > span {
    margin-bottom: 10px;
  }

  > .actions {
    margin-top: 50px;
    margin-bottom: 15px;
    svg {
      margin-top: 3px;
      margin-right: 5px;
    }
  }

  @media(min-width: 800px){
    text-align: left;
    > .actions {
      float: right;
      margin-top: -5px;
    }
  }
}

.nav {
  max-width: 400px;
}

.nav,
.section {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-bottom: 20px;
  text-align: left;
}

@media(min-width: 800px){
  .nav { width: 210px; }
  .section{
    width: calc(100% - 211px);
    padding-left: 20px;
  }
}

@media(min-width: 1100px){
  .nav { width: 280px; }
  .section{
    width: calc(100% - 281px);
    padding-left: 20px;
  }
}
