.root {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 20px 5px 20px;
  text-align: center;
  color: inherit;
  fill: currentColor;
  text-align: center;

  a {
    color: inherit;
    &:hover {
      color: currentColor;
      opacity: 0.7;
    }
    &:visited {
      color: inherit;
      fill: inherit;
    }
  }
}

.logo {
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
  > svg {
    max-height: 16px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-left: 1px solid currentColor;
    opacity: 0.5;
  }
}

.legal {
  margin: 0 20px 4px 0;
}

.social > a {
  padding: 5px;
}

// markdown generated:
.links {
  text-align: right;
  flex-grow: 2;

  a + a {
    margin-left: 10px;
  }

  p {
    margin-bottom: 5px;
  }
}

@media (max-width: 1000px) {
  .root {
    flex-direction: column;
    > span,
    > div {
      padding: 10px 5px;
    }
  }

  .logo {
    margin: 0;
    padding: 0;
    &:after {
      display: none;
    }
  }
}
