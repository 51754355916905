.panel {
  position: relative;
  margin-bottom: 20px;
  border: 0;
  border-radius: var(--BorderRadius-10);
  box-shadow: 0px 6px 12px var(--Palette-shadow-opacity-0-1);
  color: var(--paletteBlue0);
  background-color: #ffffff;
}

.panelBody {
  padding: 12px;
}
