.root {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: var(--paletteBlue0);
  padding: 10px 20px;
  border-radius: var(--BorderRadius-2) var(--BorderRadius-50) var(--BorderRadius-50) var(--BorderRadius-2);
  fill: currentColor;
  background-color: transparent;
  transition: background-color ease 200ms;

  &.danger {
    color: var(--paletteRed1);
  }

  &:hover,
  &.current {
    background-color: var(--Button-primary-bg-hover);
    color: var(--Button-primary-fg-hover);
  }

  > svg {
    margin-top: -1px;
    margin-right: 10px;
  }

  > svg,
  > span {
    vertical-align: middle;
  }
}
