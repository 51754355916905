.root {
  margin-bottom: 20px;
  text-align: center;
}

.selectWrap {
  position: relative;
  display: inline-block;
  height: 58px;
  vertical-align: top;
  margin-top: -20px;
  text-align: left;
}

.buttonWrap {
  display: inline-block;
  margin-left: 10px;
}
