.title {
  display: block;
  color: #666;
  padding: 10px 0 20px;
  font-size: 13px;
}

.list {
  padding: 0;
  margin-top: 16px;

  &:empty {
    display: none;
  }
}

.suggestions {
  margin: 0 0 20px;
}

.typeName {
  display: block;
  font-size: 15px;
}

.section {
  position: relative;
  padding: 15px 20px;
  background: var(--Palette-neutral-light);
  margin-bottom: 5px;
  min-height: 50px;
  border-radius: 2px 2px 10px 10px;
  border-top: 3px solid transparent;

  &.error {
    background-color: var(--paletteRed2);
  }
}

.sectionToggle {
  display: block;
  padding: 15px 15px 10px 12px;
  color: #000;
  fill: var(--paletteBlue0);
  opacity: 0.4;
  position: absolute;
  top: 0;
  right: 0;
}

.sectionClosed {
  cursor: pointer;
  padding: 15px 20px;
  background: var(--Palette-neutral-light);
  border-top: 3px solid var(--palettePrimary0);

  &.error {
    border-top-color: var(--paletteRed1);
  }
}

.sectionList {
  margin-top: var(--spacingLg);
  display: flex;
  flex-direction: column;
  row-gap: var(--spacingLg);

  .pillsList {
    display: flex;
    gap: var(--spacingMd);
    flex-wrap: wrap;
  }
}

.errorMessage {
  font-size: 11px;
  display: block;
  font-weight: normal;
  margin-bottom: 5px;
  color: var(--paletteRed1);
}
