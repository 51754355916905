.root {
  white-space: pre-line;
  vertical-align: baseline;
}

.more {
  color: var(--Palette-base-light);

  &:hover {
    color: var(--palettePrimary0);
  }
}

.sameLine {
  margin-left: 10px;
}
